import React from 'react'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { MainLayout, Button, ButtonLink } from '@cmp/site'
import { useForm } from 'react-hook-form'
import { useQuery, useMutation } from '@apollo/client'
import { Row, Col, Container } from '@kogk/common'
import cn from 'classnames'
import styles from './MemberSignup.module.scss'
import { GET_VIEWER_DETAILS, SIGNUP } from '../../../data/backend/queries'
import LoadingPage from '../LoadingPage/LoadingPage'
import { ValidationMessage } from '../../site/index'
import { useCartState } from '@root/src/context/Cart'

export default () => {
  const { language } = useLanguage()
  const detailsQuery = useQuery(GET_VIEWER_DETAILS)
  const { reloadCart } = useCartState()

  const { register, handleSubmit, errors } = useForm({
    mode: 'onTouched'
  })

  let [submit, { data, loading, error }] = useMutation(SIGNUP, {
    // we have to reload the cart after signup for discounts to show up
    onCompleted: reloadCart
  })

  if (!error && data?.signup?.__typename === 'ErrorMessage') {
    error = data.signup
  }

  const onSubmit = handleSubmit(input => {
    return submit({ variables: { input } })
  })

  if (detailsQuery.loading) {
    return <LoadingPage />
  }

  const viewer = detailsQuery.data.viewer
  const viewerName = viewer.details.name.split(' ')[0]

  const heading = `Hæ ${viewerName}`

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout>
        <Container>
          <Row>
            <Col className={styles.wrapper} col={{ sm: 10, md: 4 }}>
              {data && !error ? (
                <ThankYou />
              ) : viewer?.scheme === 'SNYRTIBU' ? (
                <OldMember heading={heading} />
              ) : (
                <NewMember
                  heading={heading}
                  loading={loading}
                  submitError={error}
                  errors={errors}
                  register={register}
                  onSubmit={onSubmit}
                />
              )}
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </GlobalDataProvider>
  )
}

const NewMember = ({
  heading,
  loading,
  errors,
  register,
  onSubmit,
  submitError
}) => {
  const { t } = useLanguage()

  return (
    <div>
      <h1 className='hdln--2 blue'>{heading}</h1>
      <p className='parag--small-fixed-size'>{t('snyrtibuddan|signupTitle')}</p>
      <form className='form-input' onSubmit={onSubmit}>
        <fieldset>
          <label>
            Netfang
            <input
              type='email'
              name='email'
              ref={register({
                required: { value: true, message: 'Netfang má ekki vera autt' }
              })}
            />
            <ValidationMessage message={errors.email?.message ?? false} />
          </label>
        </fieldset>
        {submitError && <ValidationMessage message={submitError.message} />}
        <Button
          className={cn(styles.button, 'parag--medium-bold px-3')}
          label={'Skrá mig í klúbbinn'}
          icon={loading ? 'loading' : null}
        />
      </form>
    </div>
  )
}

const OldMember = ({ heading }) => {
  const { t } = useLanguage()

  return (
    <div>
      <h1 className='hdln--2 blue'>{heading}</h1>
      <p className='parag--small-fixed-size mb-3 pb-2'>
        {t('snyrtibuddan|memberMessage')}
      </p>
      <ButtonLink
        to='/snyrtivorur'
        type='primary'
        label={'Skoða snyrtivörur'}
        className={cn(styles.button, 'parag--medium-bold px-3')}
      />
    </div>
  )
}

const ThankYou = () => {
  const { t } = useLanguage()

  return (
    <div>
      <h1 className='hdln--2 blue'>{t('snyrtibuddan|successTitle')}</h1>
      <p className='parag--small-fixed-size mb-3 pb-2'>
        {t('snyrtibuddan|successBody')}
      </p>
      <ButtonLink
        to='/snyrtivorur'
        type='primary'
        label={t('snyrtibuddan|successLink')}
        className={cn(styles.button, 'parag--medium-bold px-3')}
      />
    </div>
  )
}
